<!-- 上传状态弹窗 -->
<template>
  <div>
    <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'上传订单状态':'上传订单状态'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        label-width="100px">
        <el-form-item label="接单时间">
          <el-date-picker
            v-model="form.times"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="默认当前时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="位置选择">
          <!--<el-input-->
          <!--    placeholder="位置选择"-->
          <!--    v-model="form.location"-->
          <!--    clearable>-->
          <!--</el-input>-->
          <div class="xuanzedidianyangshi" @click="opensiteselection(0)">
            <span v-if="addreas == ''">请选择案件地址</span>
            <el-tooltip class="item" effect="dark" :content="addreas" placement="top-start">
              <div class="didian">{{addreas}}</div>
            </el-tooltip>
            <i class="el-icon-location-information"></i>
          </div>
          <span style="font-size: 14px;font-weight: 400;color: #ff9b05;cursor: pointer;" @click="dangqian">司机当前位置</span>
        </el-form-item>
        <!--<el-form-item label="是否接收后续接单状态" label-width="160px">-->
        <!--  <el-switch v-model="form.is_subsequent"></el-switch>-->
        <!--</el-form-item>-->
      </el-form>


      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
      center
      v-if="dialogVisible == true"
      title="选择地址"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <div>
        <!--腾讯地图选点组件-->
        <!--<div style="display: flex;">-->
        <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
        <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
        <!--</div>-->
        <!--<iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>-->
        <!--自定义高德选点组件-->
        <AMapLoadersiteselection v-if="showAMapLoadersiteselection == true" :data="origin" @location="getlocation"/>
      </div>
      <!--<div slot="footer">-->
      <!--  <el-button-->
      <!--   @click="dialogVisible = false">取消-->
      <!--  </el-button>-->
      <!--  &lt;!&ndash; <el-button-->
      <!--    style="margin-left: 20px;"-->
      <!--    type="primary"-->
      <!--    @click="dialogVisible = false">确定-->
      <!--  </el-button> &ndash;&gt;-->
      <!--</div>-->
    </el-dialog>
  </div>
</template>

<script>
//引入的接口
import {get_driver_coord, upload_driver_status} from "@/api/orderModule";
//引入自定义高德地图选点组件
import AMapLoadersiteselection from '@/components/AMapLoadersiteselection/index.vue'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    value: String
  },
  components: {AMapLoadersiteselection},
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 接单状态
      type:'',
      //选择地址
      dialogVisible:false,
      //
      showAMapLoadersiteselection:false,

      //地址
      addreas:'',
      location:[],

    };
  },
  // watch: {
  //   data() {
  //     if (this.data) {
  //       console.log(this.data)
  //       this.form = Object.assign({}, this.data);
  //       this.isUpdate = true;
  //     } else {
  //       this.form = {};
  //       this.isUpdate = false;
  //     }
  //   }
  // },
  mounted() {
    if (this.data) {
      console.log(this.data)
      console.log(this.value)
      this.form = Object.assign({}, this.data);
      console.log(this.form,'form')
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 获取下当前时间 年月日时分秒
    // 获取当前时间
    let currentTime = new Date();
    // 获取年份、月份、日期、小时、分钟、秒数
    let year = currentTime.getFullYear(); // 2021
    let month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // 01-12
    let day = currentTime.getDate().toString().padStart(2, '0'); // 01-31
    let hours = currentTime.getHours().toString().padStart(2, '0'); // 00-23
    let minutes = currentTime.getMinutes().toString().padStart(2, '0'); // 00-59
    let seconds = currentTime.getSeconds().toString().padStart(2, '0'); // 00-59
    this.form.times = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log(this.form.times)

    // 上传接单的状态
    // join：上传接单状态
    // depart：上传出发状态
    // arrive：上传到达状态
    // finish：上传完成状态
    if(this.value === '接单'){
      this.type = 'join';
    }else if(this.value === '出发'){
      this.type = 'depart';
    }else if(this.value === '到达'){
      this.type = 'arrive';
    }else if(this.value === '完成'){
      this.type = 'finish';
    }
  },
  methods: {
    //选择位置
    opensiteselection(number){
      // 用于判断是起点还是终点
      this.origin = number;
      this.dialogVisible = true;
      //this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      })
    },
    //获取选择地址后的数据
    getlocation(value){
      console.log(value)
      // 救援起点的位置
      this.addreas = value.pname + value.cityname + value.adname + value.address;
      this.location = [];
      // 救援起点的经度纬度
      this.location[0] = value.location.lng;
      this.location[1] = value.location.lat;
      this.dialogVisible = false;
    },

    //关闭
    handleClose(){
      this.dialogVisible = false;
    },

    // 获取下当前位置
    dangqian(){
      get_driver_coord(this.data.id).then(res => {
        if (res.code === 200) {
          console.log(res)
          // 经度纬度
          this.location = [res.data.longitude,res.data.latitude];
          // 地址
          this.addreas = res.data.location;
        }
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.data.id,
            times: this.form.times,
            location: this.location,
            is_subsequent: this.form.is_subsequent
          }
          upload_driver_status(this.type,data).then(res => {
            if (res.code === 200) {
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
              this.$message.success(res.msg)
            } else {
              this.loading = false;
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.loading = false;
            this.$message.error(err)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
//选择地点样式
.xuanzedidianyangshi{
  border-radius: 5px;
  -webkit-appearance: none;
  border: 1px solid var(--border-color-base);
  box-sizing: border-box;
  color: var(--color-text-regular);
  font-size: inherit;
  height: 36px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .didian{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
}
</style>
